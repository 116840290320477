import { useEffect, useState } from "react";

import i18n from "../i18n/i18n";
import { bridge } from "./bridge";
import { IS_ALPHA, IS_DEV, IS_PROD, IS_WEB } from "./config";

export let USER_AGENT = "";
export let AUTH_TOKEN = "";
export let USER_ID = "";
export let DEVICE_IDENTITY = "";
export let ACCEPT_LANGUAGE = "";
export let COUNTRY_CODE = "";
export let REGION_ID = "";

const useBridgeInfo = () => {
  const [isBridgeInit, setIsBridgeInit] = useState<boolean>(false);

  useEffect(() => {
    if (IS_DEV) {
      AUTH_TOKEN = import.meta.env.VITE_AUTH_TOKEN;
      ACCEPT_LANGUAGE = import.meta.env.VITE_ACCEPT_LANGUAGE;
      COUNTRY_CODE = import.meta.env.VITE_COUNTRY_CODE;
      DEVICE_IDENTITY = import.meta.env.VITE_DEVICE_IDENTITY;
      USER_AGENT = import.meta.env.VITE_USER_AGENT;
      USER_ID = import.meta.env.VITE_USER_ID;
      REGION_ID = import.meta.env.VITE_REGION_ID;

      i18n.changeLanguage(ACCEPT_LANGUAGE);
      setIsBridgeInit(true);
    } else if (!IS_WEB && (IS_PROD || IS_ALPHA)) {
      /**
       * 인앱 웹뷰  환경: 브릿지로 정보를 가져올수있는,
       * 실제 인앱휍뷰 환경일때 (IS_PROD or IS_ALPHA)
       * bridge로 bridge Info 가져옴
       */
      const getInformation = async () => {
        const [appRes, userRes, regionRes] = await Promise.all([
          bridge.getAppInfo({}),
          bridge.getUserInfo({}),
          bridge.getRegionInfo({}),
        ]);

        if (!appRes) {
          throw new Error(
            `bridge.app is not working : ${JSON.stringify(appRes)}`,
          );
        }

        if (!userRes) {
          throw new Error(
            `bridge.user is not working : ${JSON.stringify(userRes)}`,
          );
        }

        if (!regionRes) {
          throw new Error(
            `bridge.region is not working : ${JSON.stringify(regionRes)}`,
          );
        }

        ACCEPT_LANGUAGE = appRes.info.app.locale;
        AUTH_TOKEN = userRes.info.user.authToken;
        COUNTRY_CODE = appRes.info.app.country;
        DEVICE_IDENTITY = appRes.info.app.deviceIdentity;
        USER_AGENT = appRes.info.app.userAgent;
        USER_ID = userRes.info.user.id;
        REGION_ID = regionRes.info.region.id;

        i18n.changeLanguage(ACCEPT_LANGUAGE);
        setIsBridgeInit(true);
      };

      getInformation();
    }
  }, []);

  return { isBridgeInit };
};

export default useBridgeInfo;

import "@daangn/sprout-components-spinner/index.css";

import { Spinner } from "@daangn/sprout-components-spinner";
import { useEffect, useRef } from "react";
import { useIntersectionObserver } from "react-intersection-observer-hook";
import { useRecoilValue } from "recoil";

import { type ArticleType } from "../store/article";
import { articlesState } from "../store/article";
import { Article } from "./Article";
import * as css from "./ArticlesGrid.css";
import { EmptyPage } from "./EmptyPage";
import { IsScrolledObserver } from "./IsScrolledObserver.";

interface Props {
  fetchNextPage: Function;
  hasKeywords: boolean;
  setIsScrolled: Function;
  isFetching: boolean;
}

export const ArticlesGrid = ({
  hasKeywords,
  fetchNextPage,
  setIsScrolled,
  isFetching,
}: Props) => {
  const articles = useRecoilValue(articlesState);
  const [observerRef, { entry }] = useIntersectionObserver();
  const prevArticlesCount = useRef<number>(0);

  useEffect(() => {
    const isObserverOnScreen = entry?.isIntersecting;

    if (isObserverOnScreen) {
      const articlesCountAfterFetch = articles.length;
      const articlesCountBeforeFetch = prevArticlesCount.current;

      const isSameCountAfterFetching =
        articlesCountAfterFetch === articlesCountBeforeFetch;

      if (isSameCountAfterFetching) {
        // fetchNextPage 해도 새 아티클이 오지 않았다면 마지막 페이지로 판별
        return;
      } else {
        prevArticlesCount.current = articlesCountAfterFetch;
        fetchNextPage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry?.isIntersecting, fetchNextPage]);

  if (articles.length === 0) return <EmptyPage />;

  return (
    <>
      <IsScrolledObserver setIsScrolled={setIsScrolled} />
      <div className={!hasKeywords ? css.Grid : css.GridWithKeywords}>
        {articles.map((article: ArticleType, index: number) => {
          return <Article article={article} index={index} key={index} />;
        })}
        <div className={css.Observer} ref={observerRef} />
      </div>
      {isFetching && (
        <div className={css.Loading}>
          <Spinner size="small" />
        </div>
      )}
    </>
  );
};

import { vars } from "@seed-design/design-token";
import { useActivityParams } from "@stackflow/react";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useIntersectionObserver } from "react-intersection-observer-hook";

import { ReactComponent as ChatIcon } from "../assets/chat.svg";
import { ReactComponent as LikeIcon } from "../assets/like.svg";
import { bridge } from "../env/bridge";
import { IS_PROD } from "../env/config";
import type { ArticleType } from "../store/article";
import * as css from "./Article.css";

type Props = {
  article: ArticleType;
  index: number;
};

export const Article = ({ article, index }: Props) => {
  const { curationName }: any = useActivityParams();
  const [observerRef, { entry }] = useIntersectionObserver();
  const [isLogged, setIsLogged] = useState(false);
  const isVisible = !!entry?.isIntersecting;

  const handleClick = useCallback(() => {
    bridge?.logEvent({
      analytics: {
        target: "FIREBASE",
        name: "click_article_curation_article",
        params: JSON.stringify({
          offset: Math.floor(index / 2),
          page: Math.floor(index / 14) + 1,
          article_id: article.id,
          article_curation_name: curationName,
        }),
      },
    });

    const APP_SCHEME = IS_PROD ? "karrot://" : "karrot.alpha://";
    window.location.href = `${APP_SCHEME}articles/${article.id}?from=article_curation`;
  }, [article.id, index, curationName]);

  useEffect(() => {
    if (isVisible && !isLogged) {
      bridge?.logEvent({
        analytics: {
          target: "FIREBASE",
          name: "impression_article_curation_article",
          params: JSON.stringify({
            offset: Math.floor(index / 2),
            page: Math.floor(index / 14) + 1,
            article_id: article.id,
            article_curation_name: curationName,
          }),
        },
      });
      setIsLogged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <article
      className={css.Article}
      onClick={() => {
        handleClick();
      }}
      ref={observerRef}
    >
      <div className={css.ImageWrapper}>
        {article.status === "reserved" && (
          <div className={css.StatusBadge({ status: "reserved" })}>
            {article.status_name}
          </div>
        )}
        {article.status === "closed" && (
          <div className={css.StatusBadge({ status: "closed" })}>
            {article.status_name}
          </div>
        )}
        <img
          className={css.Image}
          src={article?.first_image ? article.first_image.file : ""}
          alt={article.title}
        />
      </div>
      <div className={css.Content}>
        <div className={css.Region}>{article.display_location_name}</div>
        <div className={css.Title}>{article.title}</div>
        <div className={css.PriceWrapper}>
          <div className={css.CurrentPrice}>{article.price_description}</div>
        </div>
        <div className={css.CountsWrapper}>
          {article.chat_rooms_count && article.chat_rooms_count > 0 ? (
            <div className={css.CountWrapper}>
              <ChatIcon style={{ fill: vars.$scale.color.gray600 }} />
              <div className={css.CountText}>{article.chat_rooms_count}</div>
            </div>
          ) : null}
          {article.watches_count && article.watches_count > 0 ? (
            <div className={css.CountWrapper}>
              <LikeIcon style={{ fill: vars.$scale.color.gray600 }} />
              <div className={css.CountText}>{article.watches_count}</div>
            </div>
          ) : null}
        </div>
      </div>
    </article>
  );
};

import * as Sentry from "@sentry/react";
import { useActivityParams } from "@stackflow/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { hoianApi } from "../apis";
import { REGION_ID } from "../env/useBridgeInfo";
import type { ArticleType } from "../store/article";
import { selectedKeywordState } from "../store/article";
import { articlesState, metadataState } from "../store/article";

export const useArticle = () => {
  const { curationName }: any = useActivityParams();

  const setArticles = useSetRecoilState(articlesState);
  const setMetadata = useSetRecoilState(metadataState);
  const selectedKeyword = useRecoilValue(selectedKeywordState);

  /** 히스토리: 페이지네이션을 위해서 maxPublishedAt과 page 파라미터 둘 다 api url에 넣어줘야한다. */
  const [maxPublishedAt, setMaxPublishedAt] = useState<number | undefined>();

  const fetchArticle = useCallback(
    async ({ pageParam }: { pageParam: number }) => {
      const urlQueryString =
        window.location.search?.replace("?", "") || undefined;

      let res = null;

      try {
        res = await hoianApi.getList({
          name: curationName,
          page: pageParam,
          selectedKeyword,
          maxPublishedAt,
          urlQueryString,
        });
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            name: curationName,
            page: pageParam,
            selectedKeyword,
            maxPublishedAt,
            urlQueryString,
            regionId: REGION_ID,
          },
        });
      }

      return { ...res, pageParam };
    },
    [maxPublishedAt, curationName, selectedKeyword],
  );

  /**
   * 무한 스크롤 fetch
   * TIP1: seletedKeyword 바뀔때마다 새 쿼리키에다가 리페칭된다(pageParam=1부터).
   * 즉, seletedKeyword 바뀔때마다 refetch()굳이 호출할 필요 없음.
   * TIP2: 쿼리키에 알아서 pageParam별로 캐싱됨.
   */
  const { data, isLoading, isFetching, fetchNextPage } = useInfiniteQuery(
    ["articleResponse", curationName, selectedKeyword],
    ({ pageParam = 1 }) => fetchArticle({ pageParam }),
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage.pageParam + 1;
      },
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  /** 데이터 세팅 */
  useEffect(() => {
    if (!Array.isArray(data?.pages)) return;

    const pages = data?.pages || [];
    const newAllArticles: ArticleType[] = [];

    pages
      .filter((page) => Array.isArray(page?.data?.articles))
      .map((page) => page.data.articles)
      .forEach((articlesOnPage) =>
        articlesOnPage?.forEach((article: ArticleType) =>
          newAllArticles.push(article),
        ),
      );

    setArticles(newAllArticles);

    if (newAllArticles.length > 0) {
      const lastArticle = newAllArticles[newAllArticles.length - 1];
      setMaxPublishedAt(new Date(lastArticle.published_at).getTime());
    }

    if (pages.length === 1 && !!pages[0]?.data?.meta) {
      setMetadata(pages[0].data.meta);
    }
  }, [data, setArticles, setMetadata]);

  return {
    isLoading,
    isFetching,
    fetchNextPage,
  };
};

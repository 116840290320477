import { useActivityParams } from "@stackflow/react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { metadataState, selectedKeywordState } from "../store/article";
import * as css from "./EmptyPage.css";

export const EmptyPage = () => {
  const { curationName }: any = useActivityParams();
  const { t } = useTranslation();
  const setSeletedKeywordState = useSetRecoilState(selectedKeywordState);
  const metadata = useRecoilValue(metadataState);

  const onClickReset = () => {
    setSeletedKeywordState("all");
  };

  // 가계부에서 쓰이는 "최근 본 게시글" 큐레이션
  if (curationName === "recently_viewed") {
    return (
      <div className={css.Empty}>
        <p className={css.Description}>{t("empty_recently_description")}</p>
      </div>
    );
  }

  if (
    !!metadata?.filter_keywords &&
    Array.isArray(metadata?.filter_keywords) &&
    metadata.filter_keywords.length > 0
  ) {
    return (
      <div className={css.Empty}>
        <p className={css.Description}>{t("empty_filter_description")}</p>
        <p className={css.ResetButton} onClick={onClickReset}>
          {t("reset_filter_button")}
        </p>
      </div>
    );
  }

  return (
    <div className={css.Empty}>
      <p className={css.Description}>{t("empty_default_description")}</p>
    </div>
  );
};

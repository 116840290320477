import * as css from "./Skeleton.css";

export const SkeletonArticles = () => {
  return (
    <section>
      <div className={css.ListContainer}>
        {new Array(4).fill(null).map((e, i) => {
          return (
            <div key={i}>
              <div className={css.SkeletonImage} />
              <div className={css.SkeletonRegion} />
              <div className={css.SkeletonTitle} />
              <div className={css.SkeletonPrice} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export const SkeletonKeyword = () => {
  return (
    <section>
      <ul className={css.skeletonKeywords}>
        <li className={css.skeletonKeyword} />
        <li className={css.skeletonKeyword} />
        <li className={css.skeletonKeyword} />
        <li className={css.skeletonKeyword} />
      </ul>
    </section>
  );
};

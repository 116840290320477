import { useActivityParams } from "@stackflow/react";
import { useEffect } from "react";

import { bridge } from "../env/bridge";

const UTM_TYPES = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
] as ReadonlyArray<string>;

export const useShowEvent = () => {
  const { curationName }: any = useActivityParams();

  useEffect(() => {
    // 아티클 큐레이션을 실행한 이전 페이지에 대한 정보 (utm)
    const utmParams: Record<string, string> = {};

    window.location.search
      ?.replace("?", "")
      .split("&")
      .forEach((param) => {
        const key = param.split("=")[0];
        if (UTM_TYPES.includes(key)) {
          const value = param.split("=")[1] || "";
          utmParams[key] = value;
        }
      });

    const logParams: Record<string, string> = {
      article_curation_name: curationName,
      type: "page_view",
    };

    if (Object.keys(utmParams).length > 0) {
      logParams.utm_params = JSON.stringify(utmParams);
    }

    bridge?.logEvent({
      analytics: {
        target: "FIREBASE",
        name: "show_article_curation",
        params: JSON.stringify(logParams),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

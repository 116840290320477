import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense, useEffect } from "react";
import { RecoilRoot } from "recoil";
import VConsole from "vconsole";

import { ErrorPage } from "./components/ErrorPage";
import { BridgeProvider } from "./env/bridge";
import { IS_PROD } from "./env/config";
import useInit from "./env/useBridgeInfo";
import { Stack } from "./stackflow";

const queryClient = new QueryClient();

const App: React.FC = () => {
  useEffect(() => {
    let vConsole: any = null;
    if (!IS_PROD) {
      vConsole = new VConsole({ theme: "dark" });
    }
    return () => {
      if (!vConsole) return;
      vConsole.destroy();
    };
  }, []);

  const { isBridgeInit } = useInit();

  return (
    <Sentry.ErrorBoundary fallback={() => <ErrorPage />}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <BridgeProvider>
            <Suspense>{isBridgeInit && <Stack />}</Suspense>
          </BridgeProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;

import { useEffect } from "react";
import { useIntersectionObserver } from "react-intersection-observer-hook";

export const IsScrolledObserver = ({
  setIsScrolled,
}: {
  setIsScrolled: Function;
}) => {
  const [observerRef, { entry }] = useIntersectionObserver();

  useEffect(() => {
    if (entry?.isIntersecting) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  }, [entry?.isIntersecting, setIsScrolled]);

  return <div ref={observerRef} />;
};

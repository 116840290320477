import { vars } from "@seed-design/design-token";
import { AppScreen } from "@stackflow/plugin-basic-ui";
import { type ActivityComponentType } from "@stackflow/react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

import { ArticlesGrid } from "../components/ArticlesGrid";
import { BannerImage } from "../components/BannerImage";
import { Keywords } from "../components/Keywords";
import { SkeletonArticles, SkeletonKeyword } from "../components/Skeleton";
import { useArticle } from "../hooks/useArticle";
import { useShowEvent } from "../hooks/useShowEvent";
import { metadataState } from "../store/article";
import * as css from "./Curation.css";

const Curation: ActivityComponentType = () => {
  const { isLoading, isFetching, fetchNextPage } = useArticle();
  const [isScrolled, setIsScrolled] = useState(false);
  const metadata = useRecoilValue(metadataState);
  const keywords = metadata?.filter_keywords;
  const hasKeywords = !!keywords && keywords.length > 0;

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [isLoading]);

  useShowEvent();

  return (
    <AppScreen
      appBar={{
        title: metadata?.title,
        borderSize: "0px",
      }}
    >
      {!metadata && isLoading ? (
        <SkeletonKeyword />
      ) : (
        hasKeywords && <Keywords keywords={keywords} />
      )}
      <div
        style={{
          borderBottom: isScrolled
            ? `0.5px solid ${vars.$semantic.color.divider2}`
            : `0.5px solid ${vars.$semantic.color.paperDefault}`,
        }}
      />
      {isLoading ? (
        <SkeletonArticles />
      ) : (
        <section
          ref={scrollRef}
          className={!hasKeywords ? css.Content : css.ContentWithKeywords}
        >
          <BannerImage bannerImage={metadata?.top_banner} />
          <ArticlesGrid
            fetchNextPage={fetchNextPage}
            hasKeywords={hasKeywords}
            setIsScrolled={setIsScrolled}
            isFetching={isFetching}
          />
          <BannerImage bannerImage={metadata?.bottom_banner} />
        </section>
      )}
    </AppScreen>
  );
};

export default Curation;

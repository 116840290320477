/**
 * 참고:
 * 공용으로 사용되는 CSS를 index.ts에 포함시켜서,
 * Code Splitting으로 인한 과도한 번들 사이즈 증가를 막습니다.
 */
import "normalize.css";
import "@seed-design/stylesheet/global.css";
import "@daangn/sprout-components-checkbox/index.css";
import "@stackflow/plugin-basic-ui/index.css";
import "./styles";

import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import {
  APP_ENV,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  IS_ALPHA,
  IS_PROD,
} from "./env/config";
import type ExtendedRumInitConfiguration from "./env/datadog";

if (IS_PROD || IS_ALPHA) {
  // country code 없는 이전 url로 접근한 유저는 unknown으로 입력돼요.
  let countryCodeFromHostname = "unknown";

  window.location.hostname.split(".").forEach((item) => {
    if (["kr", "jp", "ca", "uk"].includes(item)) {
      countryCodeFromHostname = item;
    }
  });

  /* 모니터링 비율 : 프로덕션: 한국 50% 글로벌 100% , 알파 : 100% */
  const sessionSampleRate =
    IS_PROD && countryCodeFromHostname === "kr" ? 50 : 100;

  const DATADOG_RUM_CONFIG: ExtendedRumInitConfiguration = {
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    env: APP_ENV,
    country_code: countryCodeFromHostname,
    site: "datadoghq.com",
    service: "fleamarket-curation-webview",
    sessionSampleRate,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  };

  datadogRum.init(DATADOG_RUM_CONFIG);

  Sentry.init({
    dsn: IS_PROD
      ? "https://a0a7131e91ad4f76a4dad8449fda8779@o24217.ingest.sentry.io/4505521079058432"
      : "https://080bb75ad2564be3476aea190c214cc9@o24217.ingest.sentry.io/4506756035117056",
    tracesSampleRate: 0.0,
    ignoreErrors: [
      "Java exception was raised during method invocation", // 안드로이드 앱 인스턴스가 내려갔는데, 브릿지 호출 시 발생하는 에러로 무시처리 (유저 영향 X)
      "ResizeObserver loop limit exceeded", // 크롬 일부 버전에서만 발생하는 에러로, 무해하다고 함.
      "Request aborted", // 네트워크 불안정 문제로 파악돼요
      "'text/html' is not a valid JavaScript MIME type.", // 여러 웹뷰에서 간헐적으로 기록되는 증상인데, 크티티컬하지 않다고 판단했어요.
      "Failed to fetch dynamically imported module", // 여러 웹뷰에서 간헐적으로 기록되는 증상인데, 크리티컬하지 않다고 판단했어요. (안드 웹뷰 인스턴스 릭 관련으로 추측)
      "Network Error",
    ],
  });
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<App />);

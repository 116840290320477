import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import en_CA from "./locales/en_CA.json";
import en_GB from "./locales/en_GB.json";
import ja from "./locales/ja.json";
import ko_KR from "./locales/ko_KR.json";

const DEFAULT_LANGUAGE = "en";

const resources = {
  "ko-KR": ko_KR,
  "en-CA": en_CA,
  "en-GB": en_GB,
  en,
  ja,
};

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE,
  fallbackLng: (nativeLanguageCode: string) => {
    const webLanguageCodes = {
      korean: "ko-KR",
      japanese: "ja",
      canadianEnglish: "en-CA",
      britishEnglish: "en-GB",
      english: "en",
    };
    if (/^ko(-|_|$)/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.korean, webLanguageCodes.english];
    }
    if (/^ja(-|_|$)/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.japanese, webLanguageCodes.english];
    }
    if (/^en(-|_)CA$/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.canadianEnglish, webLanguageCodes.english];
    }
    if (/^en(-|_)GB$/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.britishEnglish, webLanguageCodes.english];
    }
    if (/^en(-|_|$)/i.test(nativeLanguageCode)) {
      return [webLanguageCodes.english];
    }

    return [DEFAULT_LANGUAGE];
  },
  defaultNS: "curation",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import { useActivityParams } from "@stackflow/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { useSetRecoilState } from "recoil";

import { bridge } from "../env/bridge";
import { articlesState, selectedKeywordState } from "../store/article";
import * as css from "./Keywords.css";

interface Props {
  keywords?: string[];
}

export const Keywords = ({ keywords }: Props) => {
  const { t } = useTranslation();

  const { curationName }: any = useActivityParams();
  const [seletedKeyword, setSeletedKeyword] =
    useRecoilState(selectedKeywordState);
  const setArticles = useSetRecoilState(articlesState);

  const onClickKeyword = useCallback(
    (keyword: string) => {
      bridge?.logEvent({
        analytics: {
          target: "FIREBASE",
          name: "click_article_curation",
          params: JSON.stringify({
            article_curation_name: curationName,
            type: "filter_click",
            is_filtering: true,
            keyword: keyword === "all" ? t("all") : keyword,
          }),
        },
      });

      if (keyword === seletedKeyword) return;

      setArticles([]);
      setSeletedKeyword(keyword);
    },
    [curationName, seletedKeyword, setArticles, setSeletedKeyword, t],
  );

  return (
    <section>
      <ul className={css.Wrap}>
        <li
          onClick={() => onClickKeyword("all")}
          className={
            seletedKeyword === "all" ? css.SelectedKeyword : css.Keyword
          }
        >
          {t("all")}
        </li>
        {keywords?.map((keyword, i) => {
          return (
            <li
              key={i}
              className={
                seletedKeyword === keyword ? css.SelectedKeyword : css.Keyword
              }
              onClick={() => onClickKeyword(keyword)}
            >
              {keyword}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

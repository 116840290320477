import { vars } from "@seed-design/design-token";
import { AppScreen } from "@stackflow/plugin-basic-ui";

export const ErrorPage = () => {
  const onClickReload = () => {
    window.location.reload();
  };

  return (
    <AppScreen appBar={{ borderSize: "0px" }}>
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            padding: "0px 54px",
            textAlign: "center",
            gap: "20px",
            color: vars.$scale.color.gray600,
            ...vars.$semantic.typography.subtitle1Regular,
          }}
        >
          {"일시적으로 화면을 불러올 수 없어요."}
          <br />
          {"잠시 후 다시 시도해주세요."}
        </div>
        <button
          onClick={onClickReload}
          style={{
            width: "120px",
            height: "40px",
            borderRadius: "6px",
            border: "0px",
            background: vars.$semantic.color.secondaryLow,
            ...vars.$semantic.typography.label3Bold,
            marginTop: "38px",
            color: vars.$scale.color.gray900,
          }}
        >
          새로고침
        </button>
      </div>
    </AppScreen>
  );
};

import { karrotAnalyticsPlugin } from "@daangn/stackflow-plugin-karrot-analytics";
import { vars } from "@seed-design/design-token";
import { basicUIPlugin } from "@stackflow/plugin-basic-ui";
import { historySyncPlugin } from "@stackflow/plugin-history-sync";
import { basicRendererPlugin } from "@stackflow/plugin-renderer-basic";
import { stackflow } from "@stackflow/react";
import React from "react";

import Curation from "../activities/Curation";
import ActivityNotFound from "../activities/ActivityNotFound";
import { ReactComponent as IconX } from "../assets/icon-x.svg";
import { bridge } from "../env/bridge";
import { IS_WEB } from "../env/config";
import { COUNTRY_CODE } from "../env/useBridgeInfo";

const LogEventPlugin = IS_WEB
  ? []
  : [
      karrotAnalyticsPlugin({
        bridge,
        serviceName: "fleamarket",
        screenClassName: (activityName: string) => {
          return `accounting_report_${COUNTRY_CODE.toLowerCase()}_${activityName}`;
        },
      }),
    ];

const theme = /iphone|ipad|ipod/i.test(window.navigator.userAgent.toLowerCase())
  ? "cupertino"
  : "android";

const { Stack, useFlow } = stackflow({
  transitionDuration: 270,
  activities: {
    Curation,
    ErrorPageTest: React.lazy(() => import("../activities/ErrorPageTest")),
    ActivityNotFound,
  },
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        borderColor:
          theme === "cupertino"
            ? vars.$semantic.color.divider3
            : vars.$semantic.color.divider2,
        textColor: vars.$semantic.color.inkText,
        iconColor: vars.$semantic.color.inkText,
        closeButton: {
          renderIcon: () => (
            <IconX
              style={{
                width: "24px",
                height: "24px",
                fill: vars.$scale.color.gray900,
              }}
            />
          ),
          onClick() {
            bridge.closeRouter({});
          },
        },
      },
    }),
    historySyncPlugin({
      routes: {
        ErrorPageTest: "/errorpage/test",
        Curation: "/:curationName",
        ActivityNotFound: "/404",
      },
      fallbackActivity: () => "ActivityNotFound",
    }),
    ...LogEventPlugin,
  ],
});

export { Stack };
export type TypeUseFlow = typeof useFlow;

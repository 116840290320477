import { REGION_ID } from "../env/useBridgeInfo";
import Request from "./Request";

export const ARTICLES_COUNT_PER_PAGE = 14;

export default class HoianApi extends Request {
  constructor() {
    super();
    this.setRequestConfig();
  }

  getList({
    name,
    page,
    selectedKeyword,
    maxPublishedAt,
    urlQueryString,
  }: {
    name: string;
    page: number;
    selectedKeyword?: string;
    maxPublishedAt?: number;
    urlQueryString?: string;
  }) {
    let url = `/api/v23/article_curations/${name}/articles.json?region_id=${REGION_ID}&include=first_image&per=${ARTICLES_COUNT_PER_PAGE}&page=${page}`;

    if (selectedKeyword !== "all") {
      url += `&user_keywords=${selectedKeyword}`;
    }

    if (maxPublishedAt) {
      url += `&max_published_at=${maxPublishedAt}`;
    }

    if (urlQueryString) {
      url += `&${urlQueryString}`;
    }

    return this.req.get(url);
  }
}

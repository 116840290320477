import { useCallback } from "react";

import type { BannerType } from "../store/article";
import * as css from "./BannerImage.css";

export const BannerImage = ({ bannerImage }: { bannerImage?: BannerType }) => {
  const handleBannerClick = useCallback((targetUrl: string) => {
    window.location.href = targetUrl;
  }, []);

  if (!bannerImage) return null;

  return (
    <section
      className={css.Wrap}
      onClick={() => {
        handleBannerClick(bannerImage?.target_url);
      }}
    >
      <img
        className={css.Image}
        src={bannerImage?.image_url}
        alt={bannerImage?.description}
      />
    </section>
  );
};

import {
  installKarrotBridgeDriver,
  makeKarrotBridge,
} from "@daangn/karrotbridge";
import React, { createContext, useContext } from "react";

import { IS_DEV, IS_WEB } from "./config";

/**
 * 네이티브 앱과 통신하기 위한 Bridge에요.
 * 어떤 기능이 있는지 살펴보고 싶으시다면 아래 방법을 통해 실험실에 방문해보세요.
 * - iOS 앱: "나의 당근" -> "앱 설정" -> "미니캐롯 웹뷰 실험실 열기"
 * - 안드로이드 앱 (디버그 버전): "나의 당근" -> 우측 끝에서 좌로 스와이프 -> "웹뷰 샘플 시작하기"
 *
 * Bridge를 프로덕션 환경에서 사용하기 위해서는
 * xproperty 내에 webview_url_allowlist에 도메인 값을 추가해주어야 해요.
 * 추가하러가기: https://xproperty.kr.karrotmarket.com/admin/edit?pkey=webview_url_allowlist&country=KR
 *
 * 만약 네이티브 개발자와 함께 브릿지에 새로운 인터페이스를 기여하고 싶으시다면,
 * 아래 GitHub을 살펴보세요.
 * https://github.com/daangn/karrotbridge
 */

/* 1. 브릿지 */
export let bridge: any = null;

/* 2. Birdge Provider*/
export let BridgeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: any) => {
  return <>{children}</>;
};

/* 3. userBridge */
export let useBridge = () => {};

const setBridge = () => {
  /* 1. 브릿지 세팅*/
  const { driver } = installKarrotBridgeDriver();
  bridge = makeKarrotBridge({ driver });

  /* 2. Birdge Provider 세팅 */
  const BridgeContext = createContext(bridge);
  BridgeProvider = ({ children }) => {
    return (
      <BridgeContext.Provider value={bridge}>{children}</BridgeContext.Provider>
    );
  };

  /* 3. userBridge 세팅*/
  useBridge = () => {
    return useContext(BridgeContext);
  };
};

if (!IS_WEB || !IS_DEV) {
  setBridge();
}

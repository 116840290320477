import { atom } from "recoil";

export type ImageType = {
  id: number;
  file: string;
  picture_id: string;
  width: number;
  height: number;
};

export type ArticleType = {
  id: number;
  title: string;
  destroyed_at: null;
  status: string;
  status_name: string;
  price: string;
  price_description: string;
  display_location_name: string;
  first_image: ImageType;
  category_id: number;
  published_at: string;
  watches_count: number;
  chat_rooms_count: number;
};

export type Metadata = {
  title: string;
  top_banner?: BannerType;
  bottom_banner: BannerType;
  filter_keywords: string[];
};

export type BannerType = {
  description: string;
  image_url: string;
  target_url: string;
};

export const articlesState = atom<ArticleType[]>({
  key: "articlesState",
  default: [],
});

export const metadataState = atom<Metadata | undefined>({
  key: "metadataState",
  default: undefined,
});

export const selectedKeywordState = atom<"all" | string>({
  key: "selectedKeywordState",
  default: "all",
});

import type { ActivityComponentType } from "@stackflow/react";
import React from "react";

import { ErrorPage } from "../components/ErrorPage";

const ActivityNotFound: ActivityComponentType = () => {
  return <ErrorPage />;
};

export default ActivityNotFound;

import authPlugin from "@daangn/plantae-plugin-auth";
import requestIdPlugin from "@daangn/plantae-plugin-request-id";
import type { AxiosInstance } from "axios";
import axios from "axios";
import { createAxiosInterceptors } from "plantae";

import { bridge } from "../env/bridge";
import { IS_ALPHA, IS_DEV, IS_PROD } from "../env/config";
import {
  ACCEPT_LANGUAGE,
  AUTH_TOKEN,
  COUNTRY_CODE,
  USER_AGENT,
} from "../env/useBridgeInfo";

export default class Request {
  public req: AxiosInstance;

  constructor() {
    this.req = axios.create({
      baseURL: "",
      headers: {},
    });

    if (!IS_DEV) {
      const { request, response } = createAxiosInterceptors({
        client: this.req,
        plugins: [
          authPlugin({
            bridge,
            options: {
              fallbackAuthToken: IS_PROD ||
                IS_ALPHA || {
                  value: AUTH_TOKEN,
                },
            },
          }),
          requestIdPlugin(),
        ],
      });
      this.req.interceptors.request.use(request);
      //@ts-ignore
      this.req.interceptors.response.use(response);
    }
  }

  setRequestConfig() {
    this.req.interceptors.request.use(async (config: any) => {
      if (!config.headers) {
        return config;
      }

      const country =
        COUNTRY_CODE === "GB" ? "uk" : COUNTRY_CODE.toLocaleLowerCase();

      config.baseURL = IS_PROD
        ? `https://webapp.${country}.karrotmarket.com`
        : `https://webapp.alpha.${country}.karrotmarket.com`;
      config.headers["X-USER-AGENT"] = USER_AGENT;
      config.headers["accept-language"] = ACCEPT_LANGUAGE;

      if (IS_DEV) {
        config.headers["x-auth-token"] = AUTH_TOKEN;
      }

      return config;
    });

    this.req.interceptors.response.use(
      fleaMarket200ErrorHandlingMiddleware,
      infraMaintenanceRedirectMiddleware,
    );
  }
}

const fleaMarket200ErrorHandlingMiddleware = (res: any) => {
  // 중고거래 서버에서 200코드로 보낸 에러 응답을 throw 처리 해줘요.
  if (
    typeof res?.status === "number" &&
    res.status >= 200 &&
    res.status < 300 &&
    !!res?.data?.status?.message
  ) {
    throw new Error(JSON.stringify(res.data.status));
  }

  return res;
};

const infraMaintenanceRedirectMiddleware = (error: any) => {
  if (error?.response?.status === 599) {
    const errorText =
      error?.response?.data?.status?.message || "Service under maintenance";

    const APP_SCHEME = `karrot${
      IS_PROD ? "" : ".alpha"
    }://minikarrot/router?remote=https%3A%2F%2Fflea-fallback${
      IS_PROD ? "" : ".alpha"
    }.karrotwebview.com%2Fpages%2Fmaintenance%2Findex.html%23%2F%3Fdescription%3D${encodeURIComponent(
      errorText,
    )}&navbar=true&scrollable=false`;

    window.location.href = APP_SCHEME;
    bridge.closeRouter({});
  }

  // 오류를 다시 던져 다음 catch 블록에서 처리할 수 있게 합니다.
  return Promise.reject(error);
};
